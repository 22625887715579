import type { MenuItem, StaticNavItemsList } from '~/types';

// TODO: Some or all of these items should be fetched from the database (on load or build) per brand
export const staticNavItemsList: StaticNavItemsList = {
    alt: [
        {
            id: 'support-1',
            parent: 0,
            label: 'Support',
            path: '/support',
            url: null,
            items: [
                {
                    id: 'support-faq-1',
                    parent: 0,
                    label: 'Frequently Asked Questions',
                    path: '/faq',
                    url: null,
                    items: null
                },
                {
                    id: 'support-order-status-1',
                    parent: 0,
                    label: 'Check Order Status',
                    path: '/order-status',
                    url: null,
                    items: null
                },
                {
                    id: 'support-contact-1',
                    parent: 0,
                    label: 'Contact Us',
                    path: '/support/contact',
                    url: null,
                    items: null
                },
                {
                    id: 'support-returns-1',
                    parent: 0,
                    label: 'Returns',
                    path: '/support/returns',
                    url: null,
                    items: null
                },
                {
                    id: 'support-warranty-1',
                    parent: 0,
                    label: 'Warranty Claim',
                    path: '/support/warranty',
                    url: null,
                    items: null
                },
                {
                    id: 'support-register-1',
                    parent: 0,
                    label: 'Register A Product',
                    path: '/support/register',
                    url: null,
                    items: null
                }
            ]
        },
        {
            id: 'company-1',
            parent: 0,
            label: 'Company',
            path: '/company',
            url: null,
            items: [
                {
                    id: 'company-about-us-1',
                    parent: 0,
                    label: 'About Us',
                    path: '/company/about-us',
                    url: null,
                    items: null
                },
                {
                    id: 'company-jobs-1',
                    parent: 0,
                    label: 'Jobs',
                    path: '/company/jobs',
                    url: null,
                    items: null
                },
                {
                    id: 'company-shipping-policy-1',
                    parent: 0,
                    label: 'Shipping Policy',
                    path: '/company/shipping',
                    url: null,
                    items: null
                },
                // {
                //     id: 'company-blog-1',
                //     parent: 0,
                //     label: 'Blog',
                //     path: '/blog',
                //     url: null,
                //     items: null
                // },
                // {
                //     id: 'company-press-1',
                //     parent: 0,
                //     label: 'Press',
                //     path: '/company/press',
                //     url: null,
                //     items: null
                // },
                {
                    id: 'company-privacy-1',
                    parent: 0,
                    label: 'Privacy Policy',
                    path: '/privacy',
                    url: null,
                    items: null
                },
                {
                    id: 'products-symbol-glossary-1',
                    parent: 0,
                    label: 'UDI Symbol Glossary',
                    path: null,
                    url: 'https://stringking.com/documents/Symbol-Glossary.pdf',
                    items: null
                },
                {
                    id: 'company-tc-1',
                    parent: 0,
                    label: 'Terms & Conditions',
                    path: '/terms',
                    url: null,
                    items: null
                }
            ]
        },
        {
            id: 'products-1',
            parent: 0,
            label: 'Products',
            path: '/products/',
            url: null,
            items: [
                {
                    id: 'products-store-locator-1',
                    parent: 0,
                    label: 'Store Locator',
                    path: '/retailers/stores',
                    url: null,
                    items: null
                },
                // {
                //     id: 'products-become-retailer-1',
                //     parent: 0,
                //     label: 'Become A Retailer',
                //     path: '/onboarding/retailers',
                //     url: null,
                //     items: null
                // },
                {
                    id: 'products-catalog-1',
                    parent: 0,
                    label: 'Product Catalog',
                    path: '/product-catalog',
                    url: null,
                    items: null
                },
                {
                    id: 'products-team-sales-1',
                    parent: 0,
                    label: 'Team Sales',
                    path: '/onboarding/teams',
                    url: null,
                    items: null
                },
                // {
                //     id: 'products-refer-a-friend-1',
                //     parent: 0,
                //     label: 'Refer A Friend',
                //     path: '/refer-a-friend',
                //     url: null,
                //     items: null
                // },
                {
                    id: 'products-gift-cards-1',
                    parent: 0,
                    label: 'Gift Cards',
                    path: '/general/gift-cards/gift-card',
                    url: null,
                    items: null
                }
            ]
        },
        {
            id: 'apparel-1',
            parent: 0,
            label: 'Apparel',
            path: '/apparel',
            url: null,
            items: [
                // {
                //     id: 'apparel-corporate-orders-1',
                //     parent: 0,
                //     label: 'Corporate Orders',
                //     path: '/onboarding/organizations',
                //     url: null,
                //     items: null
                // },
                {
                    id: 'apparel-custom-fit-sizing-1',
                    parent: 0,
                    label: 'Custom Fit Sizing',
                    path: '/apparel/custom-fit-apparel/perfect-fit-algorithm',
                    url: null,
                    items: null
                },
                {
                    id: 'apparel-prem-fabric-opts-1',
                    parent: 0,
                    label: 'Premium Fabric Options',
                    path: '/apparel/custom-fit-apparel/our-fabrics',
                    url: null,
                    items: null
                }
            ]
        },
        {
            id: 'ustring-1',
            parent: 0,
            label: 'uString',
            path: '/ustring/',
            url: null,
            items: [
                {
                    id: 'ustring-tutorials-1',
                    parent: 0,
                    label: 'Stringing Tutorials',
                    path: null,
                    url: 'https://stringking.com/ustring/',
                    items: null
                },
                {
                    id: 'ustring-patterns-1',
                    parent: 0,
                    label: 'Pocket Patterns',
                    path: null,
                    url: 'https://stringking.com/ustring/patterns/',
                    items: null
                }
            ]
        }
    ]
};
